import React from 'react';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  label?: string; 
  className?: string; 
};

const CreateButton: React.FC<Props> = ({ onClick, disabled, label = 'CREATE', className }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`w-[132px] h-[40px] bg-customOrange text-white rounded-lg text-sm ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      } ${className}`} 
    >
      {label}
    </button>
  );
};

export default CreateButton;


