import React from 'react'
import { GoBell } from 'react-icons/go'

type Props = {}

const RestaurantHeader = (props: Props) => {
  return (
    <div className="flex gap-3 pr-12">
      <button className="border w-[40px] h-[40px] flex justify-center items-center rounded-lg border-gray-200 hover:bg-gray-50">
        <GoBell />
      </button>
    </div>
  )
}

export default RestaurantHeader