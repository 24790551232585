import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL = "https://dev.api.jomuntu.com/api/v1/users";

interface Role {
  id: number;
  name: string;
}

interface User {
  id: number;
  roles: Role[];
  name: string;
  email: string;
}

interface AllUsersState {
  users: User[];
  loading: boolean;
  error: string | null;
  currentRole: string;
  page: number;
  size: number;
  totalPages: number;
  totalElements: number;
}

const initialState: AllUsersState = {
  users: [],
  loading: false,
  error: null,
  currentRole: "USER",
  page: 0,
  size: 4,
  totalPages: 0,
  totalElements: 0,
};

export const fetchUsers = createAsyncThunk(
  "allUsers/fetchUsers",
  async (
    { role, page, size }: { role: string; page: number; size: number },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          role,
           page,
           size,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch users");
    }
  }
);

export const deleteUser = createAsyncThunk(
  "allUsers/deleteUser",
  async (id: number, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(`${API_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return id; // Return the deleted user's ID to update the state
    } catch (error) {
      return rejectWithValue("Failed to delete user");
    }
  }
);




const allUsersSlice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.currentRole = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.content;
        state.totalPages = action.payload.totalPages;
        state.totalElements = action.payload.totalElements;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.error = action.payload as string;
      })
  },
});

export const { setRole, setPage } = allUsersSlice.actions;

export default allUsersSlice.reducer;
