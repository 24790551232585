import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  createCategory,
  fetchCategories,
  deleteCategory,
  updateCategory,
} from "../../redux/actions/categoryActions";
import CreateButton from "../../components/CreateButton/CreateButton";
import CancelButton from "../../components/CancelButton/CancelButton";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
import EditButton from "../../components/EditButton/EditButton";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DeleteModal from "../../components/Deletemodal/DeleteModal"; 

const PAGE_SIZE = 4;

const CategoryAdmin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { categories, loading, error, totalElements } = useSelector(
    (state: RootState) => state.categories
  );
  const [categoryName, setCategoryName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [inputError, setInputError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [categoryToDelete, setCategoryToDelete] = useState<number | null>(null); 
  const [isEditing, setIsEditing] = useState(false); 
  const [editCategoryId, setEditCategoryId] = useState<number | null>(null);
  const [newCategoryName, setNewCategoryName] = useState("");

  useEffect(() => {
    dispatch(fetchCategories({ page: currentPage - 1, size: PAGE_SIZE }));
  }, [dispatch, currentPage]);

  const totalPages = Math.ceil(totalElements / PAGE_SIZE);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleCreateCategory = () => {
    if (categoryName.trim() === "") {
      setInputError("Category name cannot be empty.");
      return;
    }
  
    if (!/^[A-Za-z\s]+$/.test(categoryName)) {
      setInputError("Category name can only contain letters.");
      return;
    }
  
    if (categories.some((category) => category.name.toLowerCase() === categoryName.toLowerCase())) {
      setInputError("Category already exists.");
      return;
    }
  
    setInputError("");
    dispatch(createCategory({ categoryName, page: currentPage - 1, size: PAGE_SIZE }))
      .unwrap()
      .then(() => {
        setCategoryName("");
        setCurrentPage(1); // Reset to the first page if desired
      })
      .catch((error) => {
        console.error("Error creating category:", error);
      });
  };

  const handleCancel = () => {
    setCategoryName("");
    setInputError("");
    setIsEditing(false);
    setNewCategoryName("");
  };

  const handleDeleteCategory = (categoryId: number) => {
    setCategoryToDelete(categoryId);
    setIsModalOpen(true); 
  };

  const confirmDelete = () => {
    if (categoryToDelete !== null) {
      dispatch(deleteCategory(categoryToDelete))
        .unwrap()
        .then(() => {
          dispatch(fetchCategories({ page: currentPage - 1, size: PAGE_SIZE }));
          setIsModalOpen(false); 
        })
        .catch((error) => {
          console.error("Error deleting category:", error);
        });
    }
  };

  const startEditing = (categoryId: number, name: string) => {
    setEditCategoryId(categoryId);
    setNewCategoryName(name);
    setIsEditing(true);
  };

  const handleEditCategory = () => {
    if (newCategoryName.trim() === "") {
      setInputError("Category name cannot be empty.");
      return;
    }
  
    if (categories.some((category) => category.name === newCategoryName && category.id !== editCategoryId)) {
      setInputError("Category name must be unique.");
      return;
    }
  
    dispatch(updateCategory({ categoryId: editCategoryId!, name: newCategoryName, page: currentPage - 1, size: PAGE_SIZE }))
      .unwrap()
      .then(() => {
        setIsEditing(false);
        setEditCategoryId(null);
        setNewCategoryName("");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
      });
  };

  return (
    <div className="flex w-full">
      <div className="w-full pt-8">
        <div>
          <h1 className="text-2xl font-semibold">Category</h1>
          <p className="text-sm">
            In this section you can create and manage categories
          </p>
        </div>
        <div className="pt-14">
          <h1 className="text-lg font-semibold">Create Category</h1>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="flex w-full pt-6">
              <div className="flex flex-col w-6/12 gap-1">
                <label className="text-sm font-semibold">Add new category</label>
                <input
                  type="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  placeholder="category name"
                  className="border h-[40px] rounded-md pl-4 text-sm outline-none"
                />
                {inputError && (
                  <p className="mt-1 text-sm text-red-500">{inputError}</p>
                )}
              </div>
              <div className="flex items-end justify-end w-6/12 gap-2">
                <CreateButton onClick={handleCreateCategory} />
                <CancelButton onClick={handleCancel} />
              </div>
            </div>
          </form>
        </div>

        {/* CATEGORY TABLE */}
        <div className="w-full pt-12 text-sm font-semibold">
          <div>
            <h1>Manage all categories and subcategories</h1>
          </div>

          <div className="w-full pt-8">
            <table className="w-full">
              <thead>
                <tr className="h-[40px] border-b">
                  <th className="pl-6 font-medium text-left text-gray-500 border-r-2 ">
                    Category
                  </th>
                  <th className="w-[136px]"></th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={8} className="text-sm text-center">
                      Loading...
                    </td>
                  </tr>
                ) : error ? (
                  <tr>
                    <td colSpan={8} className="text-center text-red-500">
                      {error}
                    </td>
                  </tr>
                ) : (
                  categories.map((category, index) => (
                    <tr
                      key={category.id}
                      className={`h-[56px] border-b ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      <td className="w-11/12 pl-6 text-sm font-normal text-left border-r-2">
                        {isEditing && editCategoryId === category.id ? (
                          <>
                            <input
                              type="text"
                              value={newCategoryName}
                              onChange={(e) => setNewCategoryName(e.target.value)}
                              className="border h-[40px] rounded-md pl-4 text-sm outline-none"
                            />
                            {inputError && (
                              <p className="mt-1 text-sm text-red-500">{inputError}</p>
                            )}
                          </>
                        ) : (
                          category.name
                        )}
                      </td>
                      <td className="w-[136px]">
                        <div className="flex justify-center gap-2 px-5">
                          {isEditing && editCategoryId === category.id ? (
                            <>
                              <button
                                onClick={handleEditCategory}
                                className="px-1 text-customOrange"
                              >
                                Save
                              </button>
                              <CancelButton onClick={handleCancel} />
                            </>
                          ) : (
                            <>
                              <EditButton
                                onClick={() => startEditing(category.id, category.name)}
                              />
                              <DeleteButton
                                onClick={() => handleDeleteCategory(category.id)}
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex gap-2 mt-4 justify-left">
              <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                className="border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF] w-[40px] h-[40px] flex justify-center items-center"
              >
                <IoIosArrowBack size={10} />
              </button>

              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`w-[40px] h-[40px] flex justify-center items-center border rounded-lg text-sm ${
                    currentPage === index + 1
                      ? "border-black"
                      : "text-gray-700"
                  }`}
                >
                  {index + 1}
                </button>
              ))}

              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className="w-[40px] h-[40px] flex justify-center items-center border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF]"
              >
                <IoIosArrowForward size={10} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmDelete}
        categoryName={categories.find((category) => category.id === categoryToDelete)?.name || ''}
      />
    </div>
  );
};

export default CategoryAdmin;
