import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Navigate, Outlet } from "react-router";

type Props = {};

const ProtectedRestaurantRoute = (props: Props) => {
  const { token, isRestaurant } = useSelector((state: RootState) => state.auth);
  if (!isRestaurant) {
    return <Navigate to="/login" />;
  }

  return token && isRestaurant ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRestaurantRoute;
