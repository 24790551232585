import { DocumentUpload } from "iconsax-react";
import CancelButton from "../../components/CancelButton/CancelButton";
import CreateButton from "../../components/CreateButton/CreateButton";

type Props = {};

const KrijoMenu = (props: Props) => {
  return (
    <div className="w-full">
      <div className="flex gap-2">
        <button className="w-[155px] h-[36px] bg-customBrown text-white rounded-lg text-sm">
          Te gjitha ushqimet
        </button>
        <button className="w-[155px] h-[36px] bg-customBrown text-white rounded-lg text-sm">
          Ofertat Ditore
        </button>
      </div>
      <div className="pt-11">
        <h2 className="text-2xl font-semibold">Shto nje ushqim te ri</h2>
        <p className="text-sm">
          Krijo një ushqim te ri, pasi të konfirmohen, ato do të shfaqen në
          aplikacionin Jomuntu.
        </p>
      </div>
      <div className="flex flex-col gap-1 pt-8">
        <label className="text-sm font-semibold">
          Imazhi i ushqimit <span className="text-red-600">*</span>
        </label>
        <label className="w-full border-2 h-[124px] rounded-md border-dashed border-borderColor flex flex-col justify-center items-center cursor-pointer hover:border-customOrange">
          <div className="flex justify-center p-2 rounded-full bg-gray-50">
            <DocumentUpload color="#F85417" />
          </div>
          <h1>
            <span className="font-medium text-customOrange">
              Click To Upload
            </span>{" "}
            or drag and drop
          </h1>
          <h1 className="text-sm">(Max.File size: 25 MB)</h1>
          <input type="file" name="file_upload" className="hidden" id="image" />
        </label>
      </div>
      <div className="flex gap-3 pt-8">
        <div className="flex flex-col w-4/12 gap-1">
          <label className="text-sm font-semibold">
            Ushqimi <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            placeholder="Emri i ushqimit"
            className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-4/12 gap-1">
          <label className="text-sm font-semibold">
            Kategoria <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            placeholder="Selektoni kategorine"
            className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-4/12 gap-1">
          <label className="text-sm font-semibold">
            Qmimi <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            placeholder="$"
            className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex gap-3 pt-8">
        <div className="flex flex-col w-6/12 gap-1">
          <label className="text-sm font-semibold">
            Adresa <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            placeholder="Adresa e restaurantit"
            className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-6/12 gap-1">
          <label className="text-sm font-semibold">
            Numri Kontaktues <span className="text-red-600">*</span>
          </label>
          <input
            type="text"
            placeholder="+383 //////"
            className="border h-[40px] rounded-md pl-4 text-sm focus:border-2 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex justify-end w-full gap-2 pt-24">
        <CreateButton label="DERGO PER KONFIRMIM" className="w-[236px]"/>
        <CancelButton/>
      </div>
    </div>
  );
};

export default KrijoMenu;
