import React from 'react';
import { FiEdit3 } from "react-icons/fi";

type Props = {
  onClick: () => void; 
};

const EditButton = ({ onClick }: Props) => {
  return (
    <button 
      className="border w-[40px] h-[40px] flex justify-center items-center rounded-lg border-gray-200 hover:bg-gray-50 bg-white"
      onClick={onClick} 
    >
      <FiEdit3 size={16} />
    </button>
  );
};

export default EditButton;
