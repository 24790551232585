import { DocumentUpload } from "iconsax-react";
import { FiEdit3 } from "react-icons/fi";

type Props = {};

const RestaurantProfile = (props: Props) => {
  return (
    <div className="flex flex-col justify-between min-h-full">
      <div>
        <div className="py-8">
          <h2 className="text-2xl font-semibold">Profili i Restaurantit</h2>
          <p className="text-sm">
            Këtu mund t’i shihni të gjitha të dhënat e restorantit tuaj të
            krijuara nga Jomuntu. Po ashtu, keni mundësinë t’i modifikoni ato.
          </p>
        </div>
        <h2 className="text-sm font-semibold">Logo e Restaurantit</h2>
        <div className="flex pt-2">
          <img
            className="h-32"
            alt="rest-logo"
            src="https://s3-alpha-sig.figma.com/img/4cad/3ec2/abdb6171b1c9a05b5160d976d1f4691e?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nx8qq1mDiFcv6hhcm7znsDiOPB-XXo~dy2gJqHTC1biUremPE7-G1huKQf1lB~V8akbZ6cNQCWq3UBJBlzlbcF~6tbC7wHlLB-7zY6Yigh4TMeZ-KBKiL3G2lsjqCgy8OwC75Q92QOHdQk7Eibc3HVUQvbaB-2TXEyVhS9oxDnvn1m1LDXqcTS8Ximv6AoUpP5SQjlWZ31m5aXLQ56mRm8UQYmARP279uLoE1j7bzURq7vYZifmcsl6c3WMKCPk5Zvck2mfygF1mJU5SyQzLkIhxW3kHeIaRZegGWLdrv49IvcQomu-i-bNTyEQ8zspNEsEQoPP2DQcY-8wap8yiHA__"
          />
          <button className="flex items-center justify-center w-[28px] h-[28px] bg-gray-100 rounded-full mt-2">
            <DocumentUpload size="18" color="#F85417" variant="Outline" />
          </button>
        </div>
        <div className="flex w-full gap-4 pt-2">
          <div className="w-full">
            <h2 className="text-sm font-semibold">Emri i Restaurantit</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">Sach Pizza</p>
              <button>
                <FiEdit3 />
              </button>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm font-semibold">Kategoria e Restaurantit</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">Tradicional</p>
              <button>
                <FiEdit3 />
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4 pt-6">
          <div className="w-full">
            <h2 className="text-sm font-semibold">Emri i Pronarit</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">Emri</p>
              <button>
                <FiEdit3 />
              </button>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm font-semibold">Email Adresa e Pronarit</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">@gmail.com</p>
              <button>
                <FiEdit3 />
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4 pt-6">
          <div className="w-full">
            <h2 className="text-sm font-semibold">Adresa</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">Icon Tower - Prishtine</p>
              <button>
                <FiEdit3 />
              </button>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm font-semibold">Numri Kontaktues</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">+ 383 49 000000</p>
              <button>
                <FiEdit3 />
              </button>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4 pt-6">
          <div className="w-full">
            <h2 className="text-sm font-semibold">Email Adresa</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">restaurant@gmail.com</p>
              <button>
                <FiEdit3 />
              </button>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-sm font-semibold">Orari i Punes</h2>
            <div className="border-b h-[40px] flex items-center px-2 justify-between">
              <p className="text-sm">00:00 AM - 00:00 PM</p>
              <button>
                <FiEdit3 />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end w-full gap-4 pb-6">
        <h1 className="text-sm">
          Së shpejti do të mund të shihni si duket profili juaj në aplikacion.
        </h1>
        <button className="w-[155px] bg-[#EEEEEE] h-[36px] rounded-lg text-sm font-semibold">
          Preview profile
        </button>
      </div>
    </div>
  );
};

export default RestaurantProfile;
