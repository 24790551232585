// src/store/index.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authAdminSlice';
import allUsersReducer from '../slices/allUsersSlice';
import allPostsReducer from '../slices/allPostsSlice';
import categoryReducer from '../slices/categorySlice';
import restaurantsReducer from "../slices/allRestaurantsSlice"; 
import reviewRequestReducer from "../slices/reviewRequestSlice"
import dailyOfferReducer from "../slices/dailyOfferSlice"
export const store = configureStore({
  reducer: {
    auth: authReducer,
    allUsers: allUsersReducer,
    allPosts: allPostsReducer,
    categories: categoryReducer,
    restaurants: restaurantsReducer,
    reviewRequest: reviewRequestReducer,
    dailyOffer: dailyOfferReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
