import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import Logo from "../../assets/Logo.png";
import { IoMdArrowDropright } from "react-icons/io";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { logout } from "../../redux/slices/authAdminSlice";

type Props = {};

const SidebarRestaurant: React.FC = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>()
  const [logoutModal, setLogoutModal] = useState(false);

  const handleOpenLogoutModal = () => {
    setLogoutModal(!logoutModal);
  };

  const handleLogout = () => {
    dispatch(logout());
    setLogoutModal(false);
  };

  return (
    <div className="w-[240px] bg-sidebarcolor h-screen">
      <div className="flex flex-col items-center justify-between h-screen pt-4">
        <div className="w-full">
          <div className="w-full pl-10">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="flex flex-col items-center w-full pt-10">
            <NavLink
              to="/restaurant-panel/restaurant-profile"
              className={({ isActive }) =>
                isActive
                  ? "bg-customOrange w-full text-white py-3 pl-10 text-sm"
                  : "w-full text-white py-3 pl-10 text-sm"
              }
            >
              <div className="flex items-center gap-2">
                <RxDashboard />
                PROFILI JUAJ
              </div>
            </NavLink>
            <NavLink
              to="/restaurant-panel/krijo-menu"
              className={({ isActive }) =>
                isActive
                  ? "bg-customOrange w-full text-white py-3 pl-10 text-sm"
                  : "w-full text-white py-3 pl-10 text-sm"
              }
            >
              <div className="flex items-center gap-2">
                <RxDashboard />
                KRIJO MENU
              </div>
            </NavLink>
            <NavLink
              to="/restaurant-panel/monitorimi-kerkesave"
              className={({ isActive }) =>
                isActive
                  ? "bg-customOrange w-full text-white py-3 pl-10 text-sm"
                  : "w-full text-white py-3 pl-10 text-sm"
              }
            >
              <div className="flex items-center gap-2">
                <RxDashboard />
                MONITORIMI I KERKESAVE
              </div>
            </NavLink>
          </div>
        </div>
        <div className="pb-6">
          <button
            onClick={handleOpenLogoutModal}
            className="w-[202px] bg-white h-[48px] rounded-3xl font-medium text-sm flex justify-between items-center px-4 outline-none"
          >
            Sach Pizza
            <IoMdArrowDropright size={21} />
          </button>
        </div>
        {logoutModal && (
          <div className="absolute z-10 w-[202px] py-1  bg-white shadow-lg left-5 bottom-20 rounded-3xl">
            <button className="w-full px-4 py-2 text-sm text-left text-gray-700 " onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarRestaurant;
