import React from "react";
import SidebarAdmin from "../../components/SidebarAdmin/SidebarAdmin";
import { Outlet } from "react-router";
import AdminHeader from "../../components/AdminHeader/AdminHeader";

const AdminPanel = (props) => {
  return (
    <div className="flex w-full h-screen">
      <SidebarAdmin />
      <div className="flex flex-col items-center flex-grow h-screen bg-white">
        {/* HEADER */}
        <div className="h-[64px] w-full flex items-center justify-end">
          <AdminHeader />
        </div>
        <div className="w-11/12 h-screen">
            <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
