import React from "react";
import { IoCheckmark } from "react-icons/io5";

type Props = {
  onClick?: () => void;
};

const CheckButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button onClick={onClick} className="border w-[40px] h-[40px] flex justify-center items-center rounded-lg border-gray-200 hover:bg-gray-50 bg-white">
      <IoCheckmark size={20} color="#3D9F1D" />
    </button>
  );
};

export default CheckButton;
