import { createSlice } from '@reduxjs/toolkit';
import { fetchFoodItems, deleteFoodItem } from '../actions/allPostsActions';

export interface FoodItem {
  id: string;
  foodName: string;
  restaurantName: string;
  price: number;
  categories: string[];
  contactNumber: string;
  location: string;
  imageUrl: string;
}

interface AllPostsState {
  foodItems: FoodItem[];
  loading: boolean;
  error: string | null;
  totalElements: number;
}

const initialState: AllPostsState = {
  foodItems: [],
  loading: false,
  error: null,
  totalElements: 0,
};

const allPostsSlice = createSlice({
  name: 'allPosts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFoodItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFoodItems.fulfilled, (state, action) => {
        state.loading = false;
        state.foodItems = action.payload.foodItems;
        state.totalElements = action.payload.totalElements;
      })
      .addCase(fetchFoodItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteFoodItem.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteFoodItem.fulfilled, (state, action) => {
        state.foodItems = state.foodItems.filter(
          (item) => item.id !== action.payload
        );
        state.totalElements -= 1; 
      })
      .addCase(deleteFoodItem.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export default allPostsSlice.reducer;
