import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Fetch food items with status 'APPROVED'
export const fetchFoodItems = createAsyncThunk(
  'allPosts/fetchFoodItems',
  async ({ page, size }: { page: number; size: number }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://dev.api.jomuntu.com/api/v1/food-items', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { page, size, status: 'APPROVED' },
      });

      return {
        foodItems: response.data.content,
        totalElements: response.data.totalElements,
      };
    } catch (error: any) {
      return rejectWithValue(error.message || 'Something went wrong');
    }
  }
);

// Delete a food item by ID
export const deleteFoodItem = createAsyncThunk(
  'allPosts/deleteFoodItem',
  async (id: string, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://dev.api.jomuntu.com/api/v1/food-items/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return id; 
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to delete item');
    }
  }
);
