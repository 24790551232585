import React from 'react';

type Props = {
  onClick?: () => void; 
};

const CancelButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      className="w-[132px] h-[40px] bg-white rounded-lg text-sm border"
      onClick={onClick} 
    >
      ANULO
    </button>
  );
};

export default CancelButton;
