import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUsers,
  setPage,
  deleteUser,
} from "../../redux/slices/allUsersSlice";
import {
  fetchRestaurants,
  deleteRestaurant,
  setPage as setRestaurantPage,
} from "../../redux/slices/allRestaurantsSlice";
import { RootState, AppDispatch } from "../../redux/store";
import { CiSearch } from "react-icons/ci";
import { FiPlus } from "react-icons/fi";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
import AddRestaurantModal from "../../components/AddRestaurantModal/AddRestaurantModal";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DeleteModal from "../../components/Deletemodal/DeleteModal";
import { RestaurantDetailsModal } from "../../components/RestaurantDetailsModal/RestaurantDetailsModal";

const PAGE_SIZE = 4;

const AllUsers: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { users, loading, error, page, size, totalElements } = useSelector(
    (state: RootState) => state.allUsers
  );
  const {
    restaurants,
    loading: restaurantLoading,
    // error: restaurantError,
    page: restaurantPage,
    totalPages: restaurantTotalPages,
  } = useSelector((state: RootState) => state.restaurants);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<
    number | null
  >(null);
  const [showUsers, setShowUsers] = useState(true); // Local state to track which list is visible
  const [selectedRestaurant, setSelectedRestaurant] = useState<any | null>(
    null
  ); // This will hold the restaurant details.
  const [isRestaurantModalOpen, setIsRestaurantModalOpen] = useState(false);

  const totalPages = Math.ceil(totalElements / PAGE_SIZE);

  const handleRestaurantClick = (restaurantId: number) => {
    const restaurant = restaurants.find((r) => r.id === restaurantId);
    setSelectedRestaurant(restaurant);
    setIsRestaurantModalOpen(true);
  };

  const handlePageChange = (newPage: number) => {
    dispatch(setPage(newPage));
    dispatch(fetchUsers({ role: "USER", page: newPage, size }));
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteClick = (id: number) => {
    setSelectedUserId(id);
    setIsDeleteModalOpen(true);
  };
  const handleRestaurantDeleteClick = (id: number) => {
    setSelectedRestaurantId(id);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedUserId !== null) {
      dispatch(deleteUser(selectedUserId));
      setSelectedUserId(null);
    }
    if (selectedRestaurantId !== null) {
      dispatch(deleteRestaurant(selectedRestaurantId));
      setSelectedRestaurantId(null);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedUserId(null);
  };

  const handleListUsersClick = () => {
    setShowUsers(true); // Show users list
    dispatch(fetchUsers({ role: "USER", page, size }));
  };

  const handleListRestaurantsClick = () => {
    setShowUsers(false); // Show restaurants list
    dispatch(fetchRestaurants({ page: restaurantPage, size: PAGE_SIZE }));
  };

  

  useEffect(() => {
    if (showUsers) {
      dispatch(fetchUsers({ role: "USER", page, size }));
    }
  }, [dispatch, page, size, showUsers]);
  useEffect(() => {
    if (!showUsers) {
      dispatch(fetchRestaurants({ page: restaurantPage, size: PAGE_SIZE }));
    }
  }, [dispatch, restaurantPage, showUsers, restaurants.length]);

  const getPaginationPages = () => {
    const pages = [];
    const maxPagesToShow = 5;
    const half = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, page - half);
    let endPage = Math.min(totalPages, page + half);

    if (endPage - startPage + 1 < maxPagesToShow) {
      if (page <= half) {
        endPage = Math.min(totalPages, maxPagesToShow);
      } else {
        startPage = Math.max(1, totalPages - maxPagesToShow + 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const getRestaurantPaginationPages = () => {
    const pages = [];
    const maxPagesToShow = 5;
    const half = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, restaurantPage - half);
    let endPage = Math.min(restaurantTotalPages, restaurantPage + half);

    if (endPage - startPage + 1 < maxPagesToShow) {
      if (restaurantPage <= half) {
        endPage = Math.min(restaurantTotalPages, maxPagesToShow);
      } else {
        startPage = Math.max(1, restaurantTotalPages - maxPagesToShow + 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className="flex w-full">
      <div className="w-full pt-8">
        <h1 className="text-2xl font-semibold">All Users</h1>
        <p className="text-sm">
          In this section you can see all users, edit or add a new one.
        </p>

        {/* Modal */}
        <button
          onClick={handleOpenModal}
          className="mt-8 h-[36px] w-[204px] bg-customOrange text-sm text-white rounded-lg font-light flex justify-center gap-4 items-center outline-none"
        >
          Add new restaurant <FiPlus size={18} />
        </button>
        {isModalOpen && (
          <AddRestaurantModal isOpen={isModalOpen} onClose={handleCloseModal} />
        )}

        {/* Search and filters */}
        <div className="w-full mt-9">
          <div className="flex items-center justify-between">
            <div className="flex gap-4">
              <button
                onClick={handleListUsersClick}
                className={`w-[155px] h-[36px] rounded-lg text-sm outline-none ${
                  showUsers ? "bg-customBrown text-white" : ""
                }`}
              >
                List of all users
              </button>
              <button
                onClick={handleListRestaurantsClick}
                className={`w-[155px] h-[36px] rounded-lg text-sm outline-none ${
                  !showUsers ? "bg-customBrown text-white" : ""
                }`}
              >
                List of all restaurants
              </button>
            </div>
            <div className="relative w-[320px]">
              <input
                type="search"
                className="w-full h-[40px] border-2 rounded-lg pl-9 pr-2 text-sm outline-none"
                placeholder="Search..."
              />
              <CiSearch
                className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
                size={16}
              />
            </div>
          </div>

          {/* Show Users or Restaurants */}
          {showUsers && (
            <div className="mt-8">
              <h1 className="text-sm font-semibold">List of Users:</h1>
              <table className="w-full mt-3">
                <thead>
                  <tr className="h-[40px] border-b">
                    <th className="w-2/12 pl-6 text-sm font-medium text-left text-gray-500 border-r">
                      Full Name
                    </th>
                    <th className="w-9/12 pl-6 text-sm font-medium text-left text-gray-500 border-r">
                      Email Address
                    </th>
                    <th className="w-[136px]"></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={3} className="text-sm text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan={3} className="text-center text-red-500">
                        {error}
                      </td>
                    </tr>
                  ) : (
                    users.map((user, index) => (
                      <tr
                        key={user.id}
                        className={`h-[56px] border-b ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <td className="pl-6 text-sm border-r">{user.name}</td>
                        <td className="pl-6 text-sm border-r">{user.email}</td>
                        <td className="w-[136px]">
                          <div className="flex justify-center gap-2 px-5">
                            <DeleteButton
                              onClick={() => handleDeleteClick(user.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}

          {showUsers && (
            <div className="flex gap-2 mt-4 justify-left">
              <button
                disabled={page === 0}
                onClick={() => handlePageChange(page - 1)}
                className="border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF] w-[40px] h-[40px] flex justify-center items-center"
              >
                <IoIosArrowBack size={10} />
              </button>
              {getPaginationPages().map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber - 1)}
                  className={`w-[40px] h-[40px] flex justify-center items-center border rounded-lg text-sm ${
                    pageNumber - 1 === page ? "border-black" : "text-gray-700"
                  }`}
                >
                  {pageNumber}
                </button>
              ))}
              <button
                disabled={page === totalPages - 1}
                onClick={() => handlePageChange(page + 1)}
                className="w-[40px] h-[40px] flex justify-center items-center border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF]"
              >
                <IoIosArrowForward size={10} />
              </button>
            </div>
          )}

          {/* Show Restaurants */}
          {!showUsers && (
            <div className="mt-8">
              <h1 className="text-sm font-semibold">List of Restaurants:</h1>
              <table className="w-full mt-3">
                <thead>
                  <tr className="h-[40px] border-b">
                    <th className="w-2/12 pl-6 text-sm font-medium text-left text-gray-500 border-r">
                      Restaurant Name
                    </th>
                    <th className="w-9/12 pl-6 text-sm font-medium text-left text-gray-500 border-r">
                      Email Address
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {restaurantLoading ? (
                    <tr>
                      <td colSpan={2} className="text-sm text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : (
                    restaurants.map((restaurant, index) => (
                      <tr
                        key={restaurant.id}
                        className={`h-[56px] border-b ${
                          index % 2 === 0 ? "bg-gray-50" : "bg-white"
                        }`}
                      >
                        <td
                          className="pl-6 text-sm border-r cursor-pointer"
                          onClick={() => handleRestaurantClick(restaurant.id)}
                        >
                          {restaurant.name}
                        </td>
                        <td className="pl-6 text-sm border-r">
                          {restaurant.emailAddress}
                        </td>
                        <td className="w-[136px]">
                          <div className="flex justify-center gap-2 px-5">
                            <DeleteButton
                              onClick={() =>
                                handleRestaurantDeleteClick(restaurant.id)
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* Restaurant Pagination */}
          {!showUsers && (
            <div className="flex gap-2 mt-4 justify-left">
              <button
                disabled={restaurantPage === 0}
                onClick={() => dispatch(setRestaurantPage(restaurantPage - 1))}
                className="border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF] w-[40px] h-[40px] flex justify-center items-center"
              >
                <IoIosArrowBack size={10} />
              </button>
              {getRestaurantPaginationPages().map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => dispatch(setRestaurantPage(pageNumber - 1))}
                  className={`w-[40px] h-[40px] flex justify-center items-center border rounded-lg text-sm ${
                    pageNumber - 1 === restaurantPage
                      ? "border-black"
                      : "text-gray-700"
                  }`}
                >
                  {pageNumber}
                </button>
              ))}
              <button
                disabled={restaurantPage === restaurantTotalPages - 1}
                onClick={() => dispatch(setRestaurantPage(restaurantPage + 1))}
                className="w-[40px] h-[40px] flex justify-center items-center border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF]"
              >
                <IoIosArrowForward size={10} />
              </button>
            </div>
          )}
        </div>

        {isDeleteModalOpen && (
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={handleCloseDeleteModal}
            onConfirm={handleConfirmDelete}
          />
        )}
        {isRestaurantModalOpen && selectedRestaurant && (
          <RestaurantDetailsModal
            restaurant={selectedRestaurant}
            onClose={() => setIsRestaurantModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default AllUsers;
