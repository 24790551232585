import React from "react";
import { LuTrash } from "react-icons/lu";

type Props = {
  color?: string;
  onClick?: () => void; 
};

const DeleteButton: React.FC<Props> = ({ color = "black", onClick }) => {
  return (
    <button
      onClick={onClick} 
      className="border w-[40px] h-[40px] flex justify-center items-center rounded-lg border-gray-200 hover:bg-gray-50 bg-white"
    >
      <LuTrash color={color} size={16} />
    </button>
  );
};

export default DeleteButton;
