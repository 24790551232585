import { CiSquareRemove } from "react-icons/ci";

export const RestaurantDetailsModal: React.FC<{
  restaurant: any;
  onClose: () => void;
}> = ({ restaurant, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
      <div className="bg-white p-6 w-[1104px] pl-14 h-[522px]">
      <div className="flex justify-end w-full mb-8 pr-14">
          <button
            onClick={onClose}
          >
            <CiSquareRemove size={24}/>
          </button> 
        </div>
        <h1 className="text-xl font-medium">Restaurant details</h1>
        <div className="flex gap-4 mt-3">
          <div>
            <label className="text-sm font-semibold">Restaurant Name</label>
            <p className="text-sm border-b w-[474px] py-2 px-2">
              {restaurant?.name}
            </p>
          </div>
          <div>
            <label className="text-sm font-semibold">Email Address</label>
            <p className="text-sm border-b w-[474px] py-2 px-2">
              {restaurant?.emailAddress}
            </p>
          </div>
        </div>
        <div className="flex gap-4 mt-8">
          <div>
            <label className="text-sm font-semibold">Restaurant Logo</label>
            <p className="text-sm border-b w-[474px] px-2">
              <img
                src={restaurant?.logoUrl}
                alt="logo"
                className="h-[36px] w-[40px]"
              />
            </p>
          </div>
          <div>
            <label className="text-sm font-semibold">Opening Hours</label>
            <p className="text-sm border-b w-[474px] py-2 px-2">
              {restaurant?.openingHours}
            </p>
          </div>
        </div>
        <div className="flex gap-4 mt-8">
          <div>
            <label className="text-sm font-semibold">Owner's Name</label>
            <p className="text-sm border-b w-[474px] py-2 px-2">
              {restaurant?.ownerName}
            </p>
          </div>
          <div>
            <label className="text-sm font-semibold">
              Owner's email address
            </label>
            <p className="text-sm border-b w-[474px] py-2 px-2">
              {restaurant?.ownerEmail}
            </p>
          </div>
        </div>
        <div className="flex gap-4 mt-8">
          <div>
            <label className="text-sm font-semibold">Location</label>
            <p className="text-sm border-b w-[474px] py-2 px-2">
              {restaurant?.location}
            </p>
          </div>
          <div>
            <label className="text-sm font-semibold">Phone Number</label>
            <p className="text-sm border-b w-[474px] py-2 px-2">
              {restaurant?.phoneNumber}
            </p>
          </div>
        </div>
        
      </div>
    </div>
  );
};
