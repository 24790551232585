import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  declineFoodItem,
  approveFoodItem,
  fetchReviewRequestPosts,
} from "../../redux/slices/reviewRequestSlice";
import { fetchDailyOffers, approveDailyOffer, declineDailyOffer } from "../../redux/slices/dailyOfferSlice";
import DeleteButton from "../../components/DeleteButton/DeleteButton";
import CheckButton from "../../components/CheckButton/CheckButton";
import { CiSearch } from "react-icons/ci";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

type Props = {};

const ReviewRequest: React.FC = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, totalPages, page, loading, error } = useSelector(
    (state: RootState) => state.reviewRequest
  );
  const {
    data: dailyOfferData,
    loading: offersLoading,
    error: offersError,
    page: dailyOfferPage,
    totalPages: dailyOfferTotalPage,
  } = useSelector((state: RootState) => state.dailyOffer);

  const [showDailyOffers, setShowDailyOffers] = useState(false);

  const handleApprove = (foodItemId: number) => {
    dispatch(approveFoodItem(foodItemId));
  };

  const handleDecline = (foodItemId: number) => {
    dispatch(declineFoodItem(foodItemId));
  };

  const handleApproveOffer = (dailyOfferId: number) => {
    dispatch(approveDailyOffer(dailyOfferId))
  }

  const handleDeclineOffer = (dailyOfferId: number) => {
    dispatch(declineDailyOffer(dailyOfferId))
  }

  useEffect(() => {
    if (!showDailyOffers) {
      dispatch(fetchReviewRequestPosts(0));
    }
  }, [dispatch, showDailyOffers]);

  const handleFetchDailyOffers = () => {
    setShowDailyOffers(true);
    dispatch(fetchDailyOffers(0));
  };

  const handleFetchReviewRequests = () => {
    setShowDailyOffers(false);
    dispatch(fetchReviewRequestPosts(0));
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      dispatch(fetchReviewRequestPosts(newPage));
    }
  };

  const getPageRange = (currentPage: number, totalPages: number) => {
    const range = [];
    const visiblePages = 4; // Show 4 pages in the middle
    const half = Math.floor(visiblePages / 2);

    let start = currentPage - half;
    let end = currentPage + half;

    if (start < 0) {
      start = 0;
      end = Math.min(visiblePages - 1, totalPages - 1);
    }

    if (end >= totalPages) {
      end = totalPages - 1;
      start = Math.max(0, totalPages - visiblePages);
    }

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  };

  const pageRange = getPageRange(page, totalPages);

  const handleDailyOfferPageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < dailyOfferTotalPage) {
      dispatch(fetchDailyOffers(newPage));
    }
  };

  const getDailyOfferPageRange = (currentPage: number, totalPages: number) => {
    const range = [];
    const visiblePages = 4; // Adjust if needed
    const half = Math.floor(visiblePages / 2);

    let start = currentPage - half;
    let end = currentPage + half;

    if (start < 0) {
      start = 0;
      end = Math.min(visiblePages - 1, totalPages - 1);
    }

    if (end >= totalPages) {
      end = totalPages - 1;
      start = Math.max(0, totalPages - visiblePages);
    }

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    return range;
  };

  const dailyOfferPageRange = getDailyOfferPageRange(
    dailyOfferPage,
    dailyOfferTotalPage
  );

  return (
    <div>
      <div className="flex gap-2 mt-12">
        <button
          onClick={handleFetchReviewRequests}
          className={`w-[155px] h-[36px] justify-center flex items-center rounded-lg text-sm  ${
            !showDailyOffers ? "bg-customBrown text-white" : ""
          }`}
        >
          List of all post
        </button>
        <button
          onClick={handleFetchDailyOffers}
          className={`w-[170px] h-[36px] justify-center flex items-center rounded-lg text-sm  ${
            showDailyOffers ? "bg-customBrown text-white" : ""
          }`}
        >
          List of daily offers post
        </button>
      </div>
      <div>
        <div className="flex items-end justify-between mt-16">
          <div>
            <h1 className="text-2xl font-semibold">Review request</h1>
            <p className="text-sm">
              In this section you can see what user had upload
            </p>
          </div>
          <div className="relative w-[320px]">
            <input
              type="search"
              className="w-full h-[40px] border-2 rounded-lg pl-9 text-sm pr-2 outline-none"
              placeholder="Search..."
            />
            <CiSearch
              className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
              size={16}
            />
          </div>
        </div>
        {showDailyOffers ? (
          // Daily Offers Table
          <div>
            <table className="w-full mt-12 table-auto">
              <thead>
                <tr className="h-[40px] border-b w-full">
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Food Image
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Food Name
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Restaurant Name
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Price
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Category
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Contact Number
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Location
                  </th>
                </tr>
              </thead>
              <tbody>
                {offersLoading ? (
                  <tr>
                    <td colSpan={7}>Loading...</td>
                  </tr>
                ) : offersError ? (
                  <tr>
                    <td colSpan={7}>Error: {offersError}</td>
                  </tr>
                ) : dailyOfferData.length === 0 ? (
                  <tr>
                    <td colSpan={7}>No offers found.</td>
                  </tr>
                ) : (
                  dailyOfferData.map((offer, index) => (
                    <tr
                      key={offer.id}
                      className={`h-[56px] border-b ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        <img
                          src={offer.imageUrl}
                          alt={offer.title}
                          className="h-[36px] w-[43px]"
                        />
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {offer.title}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {offer.foodItems[0]?.restaurantName || "N/A"}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        ${offer.price.toFixed(2)}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {offer.foodItems[0]?.categories.join(", ") || "N/A"}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {offer.foodItems[0]?.contactNumber || "N/A"}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {offer.foodItems[0]?.location || "N/A"}
                      </td>
                      <td className="w-[136px]">
                        <div className="flex justify-center gap-2 px-5">
                          <CheckButton onClick={() => handleApproveOffer(offer.id)}/>
                          <DeleteButton color="#D52222"  onClick={() => handleDeclineOffer(offer.id)}/>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="flex justify-start gap-2 mt-4 pagination-controls">
              <button
                onClick={() => handleDailyOfferPageChange(dailyOfferPage - 1)}
                disabled={dailyOfferPage === 0}
                className="border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF] w-[40px] h-[40px] flex justify-center items-center"
              >
                <IoIosArrowBack size={10} />
              </button>
              {dailyOfferPageRange.map((p) => (
                <button
                  key={p}
                  onClick={() => handleDailyOfferPageChange(p)}
                  className={`w-[40px] h-[40px] flex justify-center items-center border rounded-lg text-sm ${
                    p === dailyOfferPage ? "border-black" : ""
                  }`}
                >
                  {p + 1}
                </button>
              ))}
              <button
                onClick={() => handleDailyOfferPageChange(dailyOfferPage + 1)}
                disabled={dailyOfferPage + 1 >= totalPages}
                className="border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF] w-[40px] h-[40px] flex justify-center items-center"
              >
                <IoIosArrowForward size={10} />
              </button>
            </div>
          </div>
        ) : (
          <div>
            <table className="w-full mt-12 table-auto">
              <thead>
                <tr className="h-[40px] border-b w-full">
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Food Image
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Food Name
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Restaurant Name
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2">
                    Price
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Category
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Contact Number
                  </th>
                  <th className="pl-6 text-sm font-medium text-left text-gray-500 border-r-2 ">
                    Location
                  </th>
                  <th className="w-[136px]"></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={7}>Loading...</td>
                  </tr>
                ) : error ? (
                  <tr>
                    <td colSpan={7}>Error: {error}</td>
                  </tr>
                ) : data.length === 0 ? (
                  <tr>
                    <td colSpan={7}>No posts found.</td>
                  </tr>
                ) : (
                  data.map((post, index) => (
                    <tr
                      className={`h-[56px] border-b ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                      key={post.id}
                    >
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        <img
                          src={post.imageUrl}
                          alt={post.foodName}
                          className="h-[36px] w-[43px]"
                        />
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {post.foodName}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {post.restaurantName}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        ${post.price.toFixed(2)}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {post.categories.join(", ")}
                      </td>
                      <td className="pl-6 text-sm font-normal text-left border-r-2">
                        {post.contactNumber}
                      </td>
                      <td className="pl-6 text-sm border-r-2">
                        {post.location}
                      </td>
                      <td className="w-[136px]">
                        <div className="flex justify-center gap-2 px-5">
                          <CheckButton onClick={() => handleApprove(post.id)} />
                          <DeleteButton
                            color="#D52222"
                            onClick={() => handleDecline(post.id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="flex justify-start gap-2 mt-4 pagination-controls">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 0}
                className="border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF] w-[40px] h-[40px] flex justify-center items-center"
              >
                <IoIosArrowBack size={10} />
              </button>
              {pageRange.map((p) => (
                <button
                  key={p}
                  onClick={() => handlePageChange(p)}
                  className={`w-[40px] h-[40px] flex justify-center items-center border rounded-lg text-sm ${
                    p === page ? "border-black" : ""
                  }`}
                >
                  {p + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page + 1 >= totalPages}
                className="border rounded-lg disabled:opacity-50 disabled:bg-[#E9ECEF] w-[40px] h-[40px] flex justify-center items-center"
              >
                <IoIosArrowForward size={10} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewRequest;
