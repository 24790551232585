import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { RootState } from '../redux/store';
import { isTokenExpired } from '../redux/actions/authActions';

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const [isExpired, setIsExpired] = useState(false);
  const { token, isAdmin } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const checkExpiration = async () => {
      const expired = await isTokenExpired(dispatch);
      setIsExpired(expired);
    };

    checkExpiration();
  }, [dispatch, token]);

  if (isExpired || !isAdmin) {
    return <Navigate to="/login" />;
  }

  return token && isAdmin ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
