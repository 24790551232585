import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../actions/categoryActions";

export interface Category {
  id: number;
  name: string;
}

interface CategoryState {
  categories: Category[];
  loading: boolean;
  error: string | null;
  totalElements: number;
  currentPage: number;
}

const initialState: CategoryState = {
  categories: [],
  loading: false,
  error: null,
  totalElements: 0,
  currentPage: 0,
};

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload.categories;
      state.totalElements = action.payload.totalElements;
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.categories.push(action.payload);
    });
    builder.addCase(createCategory.rejected, (state, action) => {
      state.error = action.payload as string;
    });

    builder.addCase(updateCategory.fulfilled, (state, action) => {
      const updatedCategory = action.payload;
      const categoryIndex = state.categories.findIndex(
        (category) => category.id === updatedCategory.id
      );
      if (categoryIndex !== -1) {
        state.categories[categoryIndex] = updatedCategory;
      }
    });
    builder.addCase(updateCategory.rejected, (state, action) => {
      state.error = action.payload as string;
    });

    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.categories = state.categories.filter(
        (category) => category.id !== action.payload
      );
    });
    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.error = action.payload as string;
    });
  },
});

export const { setCurrentPage } = categorySlice.actions;
export default categorySlice.reducer;
